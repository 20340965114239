import fixTip from '@/components/fixTip/index.vue'

export default function (Vue) {
    const Constructor = Vue.extend(fixTip)
    const Instance = new Constructor()
    Instance.$mount()
    document.body.appendChild(Instance.$el)
    Vue.prototype.$fixTip = (content) => {
        Instance.show = true
        Instance.content = content
    }
}

