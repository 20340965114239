<template>
  <div class="gotoButton">
    <button
      class="goBtn"
      @click="clickHandler"
      :class="screenWidth < 500 ? 'miniGotBtn' : ''"
      :style="styleList"
    >
      {{ btnText ? btnText : "GO现在报名" }}
    </button>
    <kefuCom :showKefuBox.sync="showKefuBox" v-if="showKefuBox"></kefuCom>
  </div>
</template>

<script>
import kefuCom from "./kefu.vue";
import { EventBus } from "@/utils/eventBus";
export default {
  props: [
    "btnText",
    "gotoOther",
    "styleList",
    "screenWidth",
    "showKefu",
    "gotoCareerDetail",
    "gotoTarget",
  ],
  components: { kefuCom },
  data() {
    return {
      showKefuBox: false,
    };
  },
  mounted() {},
  methods: {
    clickHandler() {
      if (this.gotoCareerDetail) {
        EventBus.$emit("scroll-to", this.gotoTarget);
      } else if (this.showKefu) {
        // 显示客服弹框
        this.showKefuBox = true;
      } else if (this.gotoOther) {
        const a = document.createElement("a");
        a.href = this.gotoOther;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        this.$store.commit("setShowBookCom", true);
      }
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.gotoButton {
  display: flex;
  justify-content: center;
  width: 100%;
  .goBtn {
    background-color: #d97236;
    z-index: 1;
    position: relative;
    overflow: hidden;
    color: #fff;
    border: 3px solid #d97236;
    font-family: Arial;
    transition: transform 0.3s ease, color 0.5s ease;
    margin: 0 10px;
    border-radius: 15px;
    padding: 10px 30px;
    font-weight: 800;
    font-size: 1.05em;
    // min-width: 161.14px;
  }

  .goBtn::before {
    content: "";
    inset: 0 0 0 0;
    margin: auto;
    height: 0px;
    width: 0px;
    position: absolute;
    background-color: #fff;
    z-index: -1;
    border-radius: 50%;
    transition: all 0.7s ease;
    left: -4px;
  }

  .goBtn:active {
    transform: scale(0.9);
  }

  .goBtn:hover {
    color: #d97236;
  }
  .goBtn:hover:before {
    height: 200px;
    width: 200px;
  }
}
@media screen and (max-width: 600px) {
  .gotoButton {
    .goBtn {
      padding: 8px 27px;
      padding: 8px 27px;
    }
  }
}
@media screen and (max-width: 500px) {
  .gotoButton {
    width: 100%;
    .miniGotBtn {
      padding: 4px 18px;
      min-width: auto;
      font-size: 12px;
    }
  }
}
</style>