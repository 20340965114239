<template>
  <div class="kfBox">
    <div class="kefuBox" v-if="showKefuBox">
      <div class="kefuContent">
        <img
          class="kefu"
          src="@/assets/common/contact/businessCard.png"
          alt=""
        />
        <div class="txt">打开微信扫一扫添加客服(wx:ymqy16816)</div>
      </div>
      <div class="btn" @click="closeBox">×</div>
    </div>
  </div>
</template>

<script>
export default {
    props:['showKefuBox'],
  data() {
    return {};
  },
  methods: {
    closeBox(){
        this.$emit('update:showKefuBox',false)
    }
  },
};
</script>

<style lang="less" scoped>
.kfBox {
    .kefuBox {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    flex-direction: column;

    z-index: 1000;
    .kefuContent {
      background: #fff;
      border-radius: 10px;
      padding: 20px;
      box-sizing: border-box;
      width: 80%;
      max-width: 430px;
      min-width: 310px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .kefu {
        width: 85%;
      }
      .txt {
        color: #999;
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .btn {
      margin-top: 30px;
      border: 2px solid #fff;
      border-radius: 9px;
      color: #fff;
      cursor: pointer;
      font-size: 30px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      line-height: 1;
    }
  }
}
</style>