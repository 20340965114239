// 创建并export路由
const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            title: '千言工厂实训营',
            isLogin: false
        },
        component: () => import("@/views/Home/index.vue")
    },
    {
        path: '/homeTwo',
        name: 'homeTwo',
        meta: {
            title: '千言工厂实训营',
            isLogin: false
        },
        component: () => import("@/views/Home/indexTwo.vue")
    },
    {
        path: '/404',
        name: 'error',
        meta: {
            title: '404页面',
            isLogin: false
        },
        component: () => import('@/views/error/404.vue')
    },
    // 所有未定义路由，全部重定向到404页
    {
        path: '*',
        redirect: '/404'
    }
]

export default routes