//导入vue和vueRouter
import Vue from "vue";
import VueRouter from "vue-router";

//   导入路由
import routes from "./routes";

// import { getToken } from "@/utils/auth";

// 对 Vue Router 的 push 和 replace 方法进行重写，以捕获并处理路由跳转时的错误。
// 具体来说，它通过重写 VueRouter.prototype.push 和 VueRouter.prototype.replace 方法，
// 在调用原始方法后捕获可能的错误，并返回错误对象本身，而不是让错误继续传播。
// const originalPush = VueRouter.prototype.push
// const originalReplace = VueRouter.prototype.replace

// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)

// }
// VueRouter.prototype.replace = function replace(location) {
//     return originalReplace.call(this, location).catch(err => err)
// }

// 创建VueRouter实例
const router = new VueRouter({
    routes
})
// 将router实例传递给Vue实例
Vue.use(VueRouter);

// 路由前置守卫
router.beforeEach(({ meta, name }, from, next) => {
    next()
    // const { title, isLogin } = meta;
    // if (title) document.title = title;

    // // 如果你去的路径不是登录页
    // if (name != 'login') {
    //     // 做登录判断
    //     console.log(getToken())
    //     if (getToken()) {
    //         next()  //token存在，直接进入页面
    //     } else if (!isLogin) {
    //         next() // 无需登录页面
    //     } else {
    //         console.log('token不存在，打回登录页')
    //         //没登录，打回登录页
    //         next('/login') //放行到login页面
    //     }
    // } else {
    //     console.log('不用做登录校验：', name)
    //     //是登录页，直接放行，让你访问
    //     next()
    // }
})
export default router;