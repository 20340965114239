import Vue from 'vue'
import App from './App.vue'

// 导入路由
import router from "./router"

// 全局引入elementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入全局样式文件
import './assets/css/base.css'


// 引入vuex
import store from './store/index';

import fixTip from '@/utils/fixTip'
Vue.use(fixTip)

import "@/assets/css/iconfont.css"

import gotoButton from "./components/gotoButton.vue"
Vue.component('gotoButton',gotoButton)


import { detectDevice } from './services/device';

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

Vue.config.productionTip = false

new Vue({
  // 挂载路由
  router,
  store,
  render: h => h(App),
  data() {
    return {
      deviceType: detectDevice()
    };
  }
}).$mount('#app')



