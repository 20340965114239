import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

//导入插件
import createPersistedState from 'vuex-persistedstate'

// 用来存储数据
const state = {
    navCode: 0,
    showBookCom:false
}
// 响应组件中的事件
const actions = {

}
// 操作数据
const mutations = {
    setNavCode(state, newVal) {
        state.navCode = newVal
    },
    setShowBookCom(state, newVal) {
        state.showBookCom = newVal
    },
}
// 用来将state数据进行加工
const getters = {

}
// 新建并暴露store
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters,
    //配置插件
    plugins: [
        createPersistedState({
            key: 'user_info',
        })
    ]
})
