// src/services/device.js
import MobileDetect from 'mobile-detect';

export function detectDevice() {
  const md = new MobileDetect(window.navigator.userAgent);
  if (md.mobile()) {
    return 'mobile';
  } else {
    return 'desktop';
  }
}